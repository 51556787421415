"use client"

import React, { useEffect, useRef, useState } from "react"
import { AllProductsIcons } from "../../all-products/icons"
import { Container } from "@/components/common/container"
import { ProductCard } from "@/components/common/product-card"

import Drawer from "@/components/ui/bottom-drawer"
import { gql, useQuery } from "@apollo/client"
import { DeviceWithQty } from "@/server/api/cartActions"
import AllProductsLeft from "../../all-products/_components/all-products-left"
import SkeletonLoader from "../../all-products/_components/all-products-skeleton"

const PRODUCTS_QUERY = gql`
  query GetProducts(
    $sort: String = "relevance_device:DESC"
    $ram: String
    $storage: String
    $processor: String
    $display: Float
    $color: JSON
    $brand: String
  ) {
    products(
      sort: $sort
      where: {
        ram: $ram
        storage: $storage
        display_size: $display
        processor: $processor
        color: $color
        brand: $brand
      }
    ) {
      _id
      slug
      ownership
      device_name
      description
      image
      payable
      ram
      reviews {
        _id
        comment
        rating
        createdAt
      }
      reviewAggregates {
        overallReviews
        overallRating
        ratingDetails {
          stars
          percentage
          reviewsCount
        }
      }
    }
  }
`

const LATEST_RELEASE_QUERY = gql`
  query GetLatestRelease(
    $sort: String = "relevance_device:DESC"
    $ram: String
    $storage: String
    $processor: String
    $display: Float
    $color: JSON
    $brand: String
    $categories: String
  ) {
    products(
      sort: $sort
      where: {
        latest_release: true
        ram: $ram
        storage: $storage
        display_size: $display
        processor: $processor
        color: $color
        brand: $brand
        categories: { name: $categories }
      }
    ) {
      _id
      slug
      ownership
      device_name
      description
      image
      payable
      reviews {
        _id
        comment
        rating
        createdAt
      }
      reviewAggregates {
        overallReviews
        overallRating
        ratingDetails {
          stars
          percentage
          reviewsCount
        }
      }
    }
  }
`
const BEST_SELLERS_QUERY = gql`
  query GetBestSellers(
    $sort: String = "relevance_device:DESC"
    $ram: String
    $storage: String
    $processor: String
    $display: Float
    $color: JSON
    $brand: String
    $categories: String
  ) {
    products(
      sort: $sort
      where: {
        isBestSeller: true
        ram: $ram
        storage: $storage
        display_size: $display
        processor: $processor
        color: $color
        brand: $brand
        categories: { name: $categories }
      }
    ) {
      _id
      slug
      ownership
      device_name
      description
      qty
      image
      payable
      reviews {
        _id
        comment
        rating
        createdAt
      }
      reviewAggregates {
        overallReviews
        overallRating
        ratingDetails {
          stars
          percentage
          reviewsCount
        }
      }
    }
  }
`

const TOP_PICKS_PERFECT_FOR_QUERY = gql`
  query devicePerfectFors(
    $sort: String = "relevance_device:DESC"
    $ram: String
    $storage: String
    $processor: String
    $display: Float
    $color: JSON
    $brand: String
    $categories: String
    $title: String
  ) {
    devicePerfectFors(where: { title: $title }) {
      title
      products(
        sort: $sort
        where: {
          ram: $ram
          storage: $storage
          display_size: $display
          processor: $processor
          color: $color
          brand: $brand
          categories: { name: $categories }
        }
      ) {
        id
        _id
        slug
        description
        device_name
        purchase_value
        storage
        ram
        payable
        brand
        is_trending
        deviceFeatures
        similarProducts {
          device_name
          brand
          id
          purchase_value
          storage
          ram
        }
        image
        reviews {
          _id
          comment
          rating
          createdAt
        }
        reviewAggregates {
          overallReviews
          overallRating
          ratingDetails {
            stars
            percentage
            reviewsCount
          }
        }
      }
    }
  }
`

export function SlugMain({ title = "All Products" }: { title?: string }) {
  const [isSortOpen, setIsSortOpen] = useState(false)
  const sortRef = useRef<HTMLDivElement>(null)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [sortDrawer, setSortDrawer] = useState(false)
  const [price, setPrice] = useState([0, 200000])

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setIsSortOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const sortingOptions = [
    "Relevance",
    "Most Recent",
    "Highest Price",
    "Lowest Price",
  ]

  const [selectedFilters, setSelectedFilters] = useState({
    brand: null,
    processor: null,
    display: null,
    color: null,
    ram: null,
    storage: null,
  })
  const [selectedSort, setSelectedSort] = useState("Relevance")

  const getSortValue = (option: string) => {
    switch (option) {
      case "Highest Price":
        return "payable:DESC"
      case "Lowest Price":
        return "payable:ASC"
      case "Most Recent":
        return "createdAt:DESC"
      default:
        return "relevance_device:DESC" // Ensure a valid default value
    }
  }

  const bestSellersCall = title?.split("-").join(" ") === "best sellers"
  const latestCall = title?.split("-").join(" ") === "latest release"
  const topPicksCategory =
    title?.split("-").join(" ") === "Graphic Designers" ||
    title?.split("-").join(" ") === "Engineering Team" ||
    title?.split("-").join(" ") === "IT Professionals" ||
    title?.split("-").join(" ") === "Finance" ||
    title?.split("-").join(" ") === "Students" ||
    title?.split("-").join(" ") === "Personal Use" ||
    title?.split("-").join(" ") === "Entrepreneurs"
  // console.log(latestCall)
  // Select the appropriate query dynamically
  const selectedQuery = bestSellersCall
    ? BEST_SELLERS_QUERY
    : latestCall
    ? LATEST_RELEASE_QUERY
    : topPicksCategory
    ? TOP_PICKS_PERFECT_FOR_QUERY
    : PRODUCTS_QUERY

  const { data, loading, error } = useQuery(selectedQuery, {
    variables: {
      sort: getSortValue(selectedSort),
      ram: selectedFilters.ram || undefined,
      storage: selectedFilters.storage || undefined,
      display: selectedFilters.display || undefined,
      processor: selectedFilters.processor || undefined,
      color: selectedFilters.color || undefined,
      brand: selectedFilters.brand || undefined,
      title: title?.split("-").join(" ") || undefined,
    },
  })


  const handleFilterChange = (key: string, value: string | number | null) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: prev[key] === value ? null : value, // Toggle filter
    }))
  }

  const clearAllFilters = () => {
    setSelectedFilters({
      brand: null,
      processor: null,
      display: null,
      color: null,
      ram: null,
      storage: null,
    })
    setPrice([10000, 200000])
  }

  let filteredProducts = topPicksCategory
    ? data?.devicePerfectFors[0]?.products?.filter((product: DeviceWithQty) => {
        const payablePrice = (product?.payable || 0) / 100 // Convert payable to actual price
        const minPrice = price[0]
        const maxPrice = price[1]
        return payablePrice >= minPrice && payablePrice <= maxPrice
      })
    : data?.products?.filter((product: DeviceWithQty) => {
        const payablePrice = (product?.payable || 0) / 100 // Convert payable to actual price
        const minPrice = price[0]
        const maxPrice = price[1]
        return payablePrice >= minPrice && payablePrice <= maxPrice
      })
    filteredProducts = filteredProducts?.sort((a: any, b: any) => b.qty - a.qty)
  if (loading) return <SkeletonLoader />
  if (error)
    return (
      <div className="text-red-600 text-center">Error: {error.message}</div>
    )

  return (
    <Container>
      <section className="flex flex-col items-center justify-center w-full my-8">
        <h1 className="text-center font-orange text-xl lg:text-3xl xl:text-4xl mb-6 capitalize">
          {title?.split("-").join(" ")}
        </h1>
        <div className="w-full flex gap-8">
          {/* LEFT SECTION */}

          <AllProductsLeft
            clearAllFilters={clearAllFilters}
            selectedFilters={selectedFilters}
            values={price}
            setValues={setPrice}
            handleFilterChange={handleFilterChange}
            className="sm:w-[30%] sm:flex hidden"
          />

          {/* RIGHT SECTION */}
          <div className="w-[95%] flex  flex-col gap-3 relative max-sm:w-full">
            {/* For Web view */}
            <div className="sm:flex  w-full justify-between  items-center hidden">
              <span className="text-[#828282] text-lg sm:flex font-gilroySemiBold">
                {filteredProducts?.length || 0} Results found
              </span>
              <div ref={sortRef} className="relative">
                <button
                  className="flex gap-2 items-center rounded-[6px] border border-[#D5D5D5] px-4 py-2"
                  onClick={() => setIsSortOpen(!isSortOpen)}
                >
                  <img src="/sort.svg" alt="sortIcon" />
                  <span className="text-black font-gilroySemiBold text-base">
                    Sort By
                  </span>
                  {isSortOpen ? (
                    <AllProductsIcons.arrowUp />
                  ) : (
                    <AllProductsIcons.arrowDown />
                  )}
                </button>

                {/* Sort Modal */}
                {isSortOpen && (
                  <div className="flex flex-col absolute top-full z-10 right-0 mt-1 w-48 bg-white shadow-md rounded-xl gap-1 py-2 px-2 border border-[#D5D5D5]">
                    {sortingOptions.map((option) => (
                      <p
                        key={option}
                        className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-[#F7F7F7] hover:rounded-[6px] text-sm font-gilroySemiBold"
                        onClick={() => {
                          setSelectedSort(option)
                          setIsSortOpen(false)
                        }}
                      >
                        <span>{option}</span>
                        {selectedSort === option && (
                          <AllProductsIcons.green_tick />
                        )}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* For Mobile View */}
            <div className="flex bg-[#F7F7F7] rounded-[7px] items-center justify-between px-4 sm:hidden">
              <button
                className="flex gap-2 items-center rounded-[6px] px-4 py-2"
                onClick={() => {
                  setFilterDrawer(!filterDrawer)
                }}
              >
                <AllProductsIcons.filters_icon />
                <span className="text-black font-gilroySemiBold text-sm">
                  Filters
                </span>
                {filterDrawer ? (
                  <AllProductsIcons.arrowUp />
                ) : (
                  <AllProductsIcons.arrowDown />
                )}
              </button>

              <div className="text-[#E1E1E1]">|</div>

              <button
                className="flex gap-2 items-center rounded-[6px] px-4 py-2"
                onClick={() => setSortDrawer(!sortDrawer)}
              >
                <img src="/sort.svg" alt="sortIcon" />
                <span className="text-black font-gilroySemiBold text-sm">
                  Sort By
                </span>
                {sortDrawer ? (
                  <AllProductsIcons.arrowUp />
                ) : (
                  <AllProductsIcons.arrowDown />
                )}
              </button>
            </div>

            {filterDrawer && (
              <Drawer
                isOpen={filterDrawer}
                onClose={() => {
                  setFilterDrawer(false)
                }}
                className="bg-[#F7F7F7]"
              >
                <AllProductsLeft
                  clearAllFilters={clearAllFilters}
                  handleFilterChange={handleFilterChange}
                  values={price}
                  setValues={setPrice}
                  selectedFilters={selectedFilters}
                  className="sm:hidden w-full flex"
                />
              </Drawer>
            )}

            {sortDrawer && (
              <Drawer
                isOpen={sortDrawer}
                onClose={() => {
                  setSortDrawer(false)
                }}
                className="bg-white"
              >
                <div>
                  {sortingOptions.map((option) => (
                    <p
                      key={option}
                      className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-[#F7F7F7] hover:rounded-[6px] text-sm font-gilroySemiBold"
                      onClick={() => {
                        setSelectedSort(option)
                        // setIsSortOpen(false);
                      }}
                    >
                      <span>{option}</span>
                      {selectedSort === option && (
                        <AllProductsIcons.green_tick />
                      )}
                    </p>
                  ))}
                </div>
              </Drawer>
            )}

            <div className="justify-center flex h-full items-center">
              {filteredProducts.length > 0 ? (
                <div className="w-full h-full grid grid-cols-2 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 justify-items-center gap-y-5 lg:gap-y-8 lg:px-3 mb-2 lg:mb-8 lg:gap-x-9">
                  {filteredProducts.map((product: DeviceWithQty, i: number) => (
                    <ProductCard
                      product={product}
                      res={3}
                      key={`${i}-${product.device_name}`}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full gap-12 max-sm:mt-16">
                  <img src="/no-products.svg" className="sm:size-40 size-32" />
                  <div className="font-gilroy h-12 w-full text-center font-semibold leading-[19px] tracking-[0px]">
                    <p className="text-center font-gilroySemiBold sm:text-xl leading-[19px]">
                      {"No Products Available"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}
